import React from "react";
import Header from "./header";
import Footer from "./footer";
const SiteLayout = (props) => {
  return (
    <div >
      <Header />
      <div className="">
      {props.children}
      </div>
      <Footer />
    </div>
  );
};

export default SiteLayout;
