import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import AddUserForm from "./addUserForm";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import EditUser from "./editUser";
import UserData from "./userData";
import Loader from "../loader";
import PaymentStatus from "./paymentStatus";
import AdmitCard from "./admitCard";

const UserList = () => {
  const [userList, setUserList] = useState([]);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [update, setUpdate] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [user, setUser] = useState("");
  const [admitCardModal, setAdmitCardModal] = useState(false);
  const [editUser, setUserEdit] = useState(false);
  const [userId, setUserId] = useState("");
  const [userDetail, setUserDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageBox, setMessageBox] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [paymentView, setPaymentView] = useState(false);
  const [payments, setPayments] = useState([""]);
  const [itemsPerPage, setItemsPerPage] = useState(10); // You can set the default value as per your requirement
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentUpdate, setPaymentUpdate] = useState(false);
  const fetchUsers = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/api/user`
    );
    setUserList(response.data);
  };
  useEffect(() => {
    try {
      fetchUsers();
    } catch (error) {
      console.log(error);
    }
  }, [update, paymentUpdate]);

  const refreshPayment = () => {
    setPaymentUpdate(!paymentUpdate);
  };
  const fetchPayments = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/api/form/payment/get-user`,
      {
        headers: {
          "user-token": Cookies.get("user-token"),
        },
      }
    );
    setPayments(response.data);
  };
  useEffect(() => {
    try {
      fetchPayments();
    } catch (error) {
      console.log(error);
    }
  }, [update]);

  const closeAddUserForm = () => {
    setShowAddUserForm(false);
  };

  const closeUserDetails = () => {
    setUserDetail(false);
  };

  const toggleState = () => {
    setUpdate(!update);
  };

  const deleteUser = async (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const token = Cookies.get("user-token")
          ? Cookies.get("user-token")
          : null;
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_API_URL}/api/user/delete/${userId}`,
            {
              headers: {
                "user-token": token,
              },
            }
          );
          if (response.data.message) {
            Swal.fire("Deleted!", "User has been deleted.", "success");
            setUpdate(!update);
          }
        }
      }
    });
  };
  const updateUserStatus = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Set this form status to ${status}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update now",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const token = Cookies.get("user-token")
          ? Cookies.get("user-token")
          : null;
        if (token) {
          const response = await axios.put(
            `${process.env.REACT_APP_BASE_API_URL}/api/form/update/user-status/${userId}`,
            { status, message },
            {
              headers: {
                "user-token": token,
              },
            }
          );
          if (response.data.message) {
            Swal.fire("Updated!", response.data.message, "success");
            setLoading(false);
            setUpdate(!update);
            setMessageBox(false);
          }
        }
      }
    });
  };

  // Search functionality
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset current page to 1 when searching
  };

  // Pagination
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const filteredUsers = userList.filter((user) =>
    user?.fullName?.toLowerCase().includes(searchTerm?.toLowerCase())
  );
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const uploadUserPayment = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/form/set-payment/${userId}`,
        {
          headers: {
            "user-token": Cookies.get("user-token"),
          },
        }
      );
      if (response.data.message) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: response.data.message,
        });
        fetchUsers();
        fetchPayments();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteUserPayment = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_API_URL}/api/form/payment/delete/${id}`,
        {
          headers: {
            "user-token": Cookies.get("user-token"),
          },
        }
      );
      if (response.data.message) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: response.data.message,
        });
        fetchUsers();
        fetchPayments();
        setPaymentView(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleExportClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/form/export`
      );
      console.log(response);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  return (
    <div className="w-full rounded-lg border border-gray-200 shadow-md">
      {admitCardModal && (
        <div className="fixed overflow-y-scroll h-screen top-0 bg-white w-full right-0 z-[999]">
          <div className="p-2">
            <button
              onClick={() => setAdmitCardModal(false)}
              className="bg-red-600 px-2 text-white py-1 rounded float-right"
            >
              close
            </button>
          </div>
          <AdmitCard userId={userId} />
        </div>
      )}
      {paymentView && (
        <div className="fixed w-[100%] inset-0 z-[999] h-screen overflow-y-auto">
          <div className="w-[50%] mx-auto bg-white shadow">
            <nav
              className="flex px-5 py-3 text-gray-700 bg-gray-50 dark:bg-[#1E293B] justify-between"
              aria-label="Breadcrumb"
            >
              <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                  <Link
                    className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                    to="/"
                  >
                    <svg
                      className="w-4 h-4 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6.293 7.293a1 1 0 0 1 0 1.414L3.414 12H16a1 1 0 0 1 0 2H3.414l2.879 2.879a1 1 0 1 1-1.414 1.414l-4.586-4.586a1 1 0 0 1 0-1.414L6.293 7.293zM13 6a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1zm-2-4a1 1 0 0 1 2 0v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0V6H9a1 1 0 0 1 0-2h2V2z"
                      />
                    </svg>
                    Home
                  </Link>
                </li>
                <li className="text-gray-400">{">"}</li>
                <li className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                  User's payments
                </li>
              </ol>
              <button
                onClick={() => setPaymentView(false)}
                className="text-gray-100 bg-red-600 px-2 py-1 text-sm rounded"
              >
                close
              </button>
            </nav>
            {payments.map((item, i) => (
              <div className="flex flex-row gap-4 p-4 shadow">
                <p>{i + 1}</p>
                <p className="text-base text-gray-700 font-semibold">
                  {item.user?.fullName}
                </p>
                <p>{item.user?.email}</p>
                <p>{item.user?.phone}</p>
                <p className="text-green-600 px-2 py-1 text-sm rounded">
                  payment completed
                </p>
                <button
                  onClick={() => deleteUserPayment(item._id)}
                  className=" text-red-600 px-2 py-1 rounded"
                >
                  remove
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      {loading && <Loader />}
      {userDetail && (
        <UserData closeUserDetails={closeUserDetails} userId={userId} />
      )}
      {messageBox && (
        <div class="bg-white p-4 rounded-lg shadow-lg fixed right-0 top-0 z-[999] w-[40%]">
          <button
            onClick={() => setMessageBox(false)}
            class="bg-red-600 text-white px-2 py-1 capitalize rounded ml-2"
          >
            <i class="fas fa-times"></i>
          </button>
          <p class="text-lg font-semibold">Notify this user via email</p>
          <div class="mt-4">
            <label
              for="status"
              class="block text-gray-700 text-sm font-bold mb-2"
            >
              Status:
            </label>
            <select
              id="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              class="w-full border border-gray-300 rounded p-2"
            >
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>
          <div class="mt-4">
            <label
              for="message"
              class="block text-gray-700 text-sm font-bold mb-2"
            >
              Message:
            </label>
            <textarea
              id="message"
              placeholder="Enter your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              class="w-full border border-gray-300 rounded p-2"
            ></textarea>
          </div>
          <button
            onClick={() => updateUserStatus()}
            class="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Send Message
          </button>
        </div>
      )}
      <nav
        className="flex px-5 py-3 text-gray-700 bg-gray-50 dark:bg-[#1E293B] "
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <Link
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
              to="/"
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6.293 7.293a1 1 0 0 1 0 1.414L3.414 12H16a1 1 0 0 1 0 2H3.414l2.879 2.879a1 1 0 1 1-1.414 1.414l-4.586-4.586a1 1 0 0 1 0-1.414L6.293 7.293zM13 6a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1zm-2-4a1 1 0 0 1 2 0v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0V6H9a1 1 0 0 1 0-2h2V2z"
                />
              </svg>
              Home
            </Link>
          </li>
          <li className="text-gray-400">{">"}</li>
          <li className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
            Users
          </li>
        </ol>
      </nav>
      {/* <button onClick={handleExportClick}>Export User Data to Excel</button> */}
      <div className="p-5 w-full">
        <div className="flex justify-between items-center">
          <div className="relative w-[18rem]">
            <input
              type="text"
              className="w-full pl-10 pr-4 py-2 rounded-lg border-1 border-gray-200 focus:border-blue-500 focus:outline-none"
              placeholder="Search student"
              value={searchTerm}
              onChange={handleSearch}
            />
            <div className="absolute top-0 left-0 mt-2 ml-3">
              <svg
                className="w-4 h-4 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 15l-2.807-2.807M9 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0z"
                ></path>
              </svg>
            </div>
          </div>
          <div>
            <button
              onClick={() => setPaymentView(true)}
              className="capitalize mr-2 text-sm text-gray-100 bg-slate-900 px-2 py-1 rounded"
            >
              view payment status
            </button>
          </div>
        </div>
        <div className="w-full lg:overflow-hidden overflow-x-scroll">
          <table className="w-full divide-y divide-gray-200 mt-4 lg:overflow-hidden overflow-x-auto">
            <thead className="bg-gray-50 dark:bg-[#1E293B]">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  S.N
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  User Name/Email
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Phone Number
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Payment
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentUsers.map((user, i) => (
                <tr key={i}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{i + 1}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="">
                        <div className="text-sm font-medium text-gray-900">
                          {user.fullName}
                        </div>
                        <div className="text-sm text-gray-500">
                          {user.email}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{user.phone}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      <PaymentStatus
                        refreshPayment={refreshPayment}
                        userId={user._id}
                      />
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {user.status === "approved" ? (
                        <button className="bg-green-600 text-white px-2 py-1 capitalize rounded">
                          {user.status}
                        </button>
                      ) : (
                        <button className="bg-red-600 text-white px-2 py-1 capitalize rounded">
                          {user.status}
                        </button>
                      )}
                      <button
                        onClick={() => {
                          setUserId(user._id);
                          setMessageBox(true);
                        }}
                        className="bg-red-600 text-white px-2 py-1 capitalize rounded ml-2"
                      >
                        <i className="fas fa-edit"></i>
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => {
                        setUserId(user._id);
                        setUserDetail(true);
                      }}
                      className="text-gray-100  mr-2 bg-slate-600 px-2 py-1 rounded"
                    >
                      View
                    </button>
                    {user.role != "admin" && (
                      <button
                        className="text-gray-100 bg-blue-600 px-2 py-1 rounded mr-2"
                        onClick={() => deleteUser(user._id)}
                      >
                        Delete
                      </button>
                    )}
                    <button
                      onClick={() => {
                        setAdmitCardModal(true);
                        setUserId(user._id);
                      }}
                      className="text-gray-100  mr-2 bg-slate-600 px-2 py-1 rounded"
                    >
                      view admit card
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4">
            <label
              htmlFor="itemsPerPage"
              className="text-sm font-medium text-gray-500 mr-2"
            >
              Items per Page:
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value, 10)); // Parse the value to an integer
                setCurrentPage(1); // Reset current page when changing items per page
              }}
              className="border border-gray-300 rounded p-2"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={150}>150</option>
              <option value={200}>200</option>
            </select>
          </div>
          <div className="mt-4">
            <nav
              className="flex items-center justify-between"
              aria-label="Pagination"
            >
              {/* Add page numbers */}
              <div className="hidden sm:block">
                <ul className="flex">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li key={index}>
                      <button
                        onClick={() => paginate(index + 1)}
                        className={`font-semibold text-lg ${
                          currentPage === index + 1
                            ? "bg-blue-600 text-gray-50 text-sm rounded-full py-2"
                            : "text-gray-500 hover:text-blue-500"
                        } px-3 py-2`}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="mt-4">
          <nav
            className="flex items-center justify-between"
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium">
                  {indexOfFirstUser + 1}-
                  {Math.min(indexOfLastUser, filteredUsers.length)}
                </span>{" "}
                of <span className="font-medium">{filteredUsers.length}</span>{" "}
                results
              </p>
            </div>
            <div className="flex-1 flex justify-between sm:justify-end">
              <button
                className={`${
                  currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
                } relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 text-sm font-medium text-gray-500 bg-white hover:bg-gray-50`}
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.707 5.293a1 1 0 0 1 0 1.414L7.414 10l3.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <button
                className={`${
                  currentPage === totalPages
                    ? "cursor-not-allowed opacity-50"
                    : ""
                } ml-3 relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 text-sm font-medium text-gray-500 bg-white hover:bg-gray-50`}
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <span className="sr-only">Next</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.293 14.707a1 1 0 0 1 0-1.414L12.586 10l-3.293-3.293a1 1 0 0 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </nav>
        </div>
      </div>
      {showAddUserForm && (
        <AddUserForm
          closeAddUserForm={closeAddUserForm}
          toggleState={toggleState}
        />
      )}
      {editUser && <EditUser user={user} />}
    </div>
  );
};

export default UserList;
