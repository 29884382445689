import React, { useState, useEffect } from "react";
import KhaltiCheckout from "khalti-checkout-web";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Loader from "../loader";
// export const ESEWA_TEST_PID = "Esewa_Task_1";
export const ESEWA_SCD = "NP-ES-MADHESH";
// export const ESEWA_SCD = "EPAYTEST";
export const ESEWA_URL = "https://esewa.com.np/epay/main";
export const ESEWA_FRAUD_TEST_URL = "https://esewa.com.np/epay/transrec";
let form = null;

const AccountInfo = () => {
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    amt: 2000,
    psc: 0,
    pdc: 0,
    txAmt: 0,
    tAmt: 2000,
    pid: JSON.parse(Cookies.get("user-detail"))._id,
    // pid:ESEWA_TEST_PID,
    scd: ESEWA_SCD,
    su: `${process.env.REACT_APP_CLIENT_URL}/success`,
    fu: `${process.env.REACT_APP_CLIENT_URL}/failed`,
  });
  const post = () => {
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", ESEWA_URL);

    for (const [key, value] of Object.entries(params)) {
      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", value);
      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    form.submit();
  };

  const handleSubmit = () => {
    post();
  };

  const khaltiPay = () => {
    let config = {
      publicKey: process.env.REACT_APP_Live_Public_Key,
      productIdentity: "1234567890",
      productName: "Drogon",
      productUrl: "http://gameofthrones.com/buy/Dragons",
      eventHandler: {
        async onSuccess(payload) {
          setLoading(true);
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_API_URL}/api/form/payment/verify-khalti`,
            { payload },
            {
              headers: {
                "user-token": Cookies.get("user-token"),
              },
            }
          );
          setLoading(false);
          Swal.fire("Success", response.data.message, "success");
        },
        onError(error) {
          console.log(error);
        },
        onClose() {
          console.log("widget is closing");
        },
      },
      paymentPreference: [
        "KHALTI",
        "EBANKING",
        "MOBILE_BANKING",
        "CONNECT_IPS",
        "SCT",
      ],
    };
    let checkout = new KhaltiCheckout(config);
    checkout.show({ amount: 200000 });
  };

  return (
    <div className="w-full max-w-xl mx-auto mt-[2rem] shadow-md text-center pb-6">
      {loading && <Loader />}
      <p className="lg:text-2xl text-sm font-semibold text-gray-600 py-8 font-serif">
        Pay: Rs 2000/- Using
      </p>
      <div className="flex lg:flex-row flex-col gap-4 justify-center lg:px-4 px-2">
        <div
          onClick={() => khaltiPay()}
          className="bg-purple-800 border-2 text-white p-4 rounded-lg cursor-pointer hover:bg-transparent hover:border-purple-800 hover:text-purple-800 text-xl"
        >
          <p>Khalti</p>
        </div>
        <div
          onClick={handleSubmit}
          className="bg-green-600  border-2 text-white p-4 rounded-lg cursor-pointer hover:bg-transparent hover:border-green-800 hover:text-green-800 text-xl"
        >
          <p>E-sewa</p>
        </div>
      </div>
      <p className="text-lg text-justify p-4 tracking-wider text-gray-600">
        Please complete the payment first to proceed further with your request.
        Your payment is an essential step to ensure the successful processing of
        your request. Once the payment is confirmed, we'll be able to continue
        with the next steps. Thank you for your cooperation.
      </p>
    </div>
  );
};

export default AccountInfo;
