import React from "react";
import UserList from "../../components/admin/userList";

const UserListPage = () => {
  return (
    <div>
      <UserList />
    </div>
  );
};

export default UserListPage;
