import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "../loader";
import Cookies from "js-cookie";
const EditUser = ({ user }) => {
  const [errors, setErrors] = useState([""]);
  const [fullName, setFullName] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setloading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const formData = {
    fullName,
    email,
    officeName,
    phone,
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setloading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/user/update`,
        formData,
        {
          headers: {
            "user-token": Cookies.get("user-token"),
          },
        }
      );
      if (response.data.message) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: response.data.message,
        });
        setloading(false);
      }
      setErrors(response.data.errors);
      setloading(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <div className="container mx-auto">
        <div className="absolute top-0 z-[999]  inset-0 flex items-center justify-center">
          <div className="flex justify-center lg:px-6 px-2 lg:my-12 lg:w-[50%] w-full">
            <div className="w-full flex shadow-md rounded">
              <div className="w-full bg-white p-5 rounded-lg lg:rounded-l-none">
                <button
                  //   onClick={() => closeAddUserForm()}
                  className="bg-red-600 float-right px-2 py-1 text-white rounded"
                >
                  close
                </button>
                <h3 className="pt-4 text-2xl text-center">Edit Account!</h3>
                <form
                  className="lg:px-8 pt-6 pb-8 mb-4 bg-white rounded w-full"
                >
                  <div className="mb-6">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      for="fullName"
                    >
                      Name of user
                    </label>
                    <input
                      className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="firstName"
                      type="text"
                      name="fistName"
                      value={user.fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="mb-6">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      for="officeName"
                    >
                      Name of Office {"(optional)"}
                    </label>
                    <input
                      className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="officeName"
                      type="text"
                      name="officeName"
                      value={user.officeName}
                      onChange={(e) => setOfficeName(e.target.value)}
                      placeholder="Name of the office"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      for="email"
                    >
                      Email
                    </label>
                    <input
                      className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="email"
                      type="email"
                      name="email"
                      value={user.email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />
                  </div>
                  <div className="mb-6">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      for="phoneNumber"
                    >
                      Phone Number
                    </label>
                    <input
                      className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="phoneNumber"
                      type="text"
                      name="phoneNumber"
                      value={user.phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="phone number"
                    />
                  </div>
                  <div>
                    <p>change password</p>
                    <div className="mb-1 md:flex md:justify-between">
                      <div className="mb-4 md:mr-2 md:mb-0">
                        <label
                          className="block mb-2 text-sm font-bold text-gray-700"
                          for="password"
                        >
                          Password
                        </label>
                        <input
                          className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                          id="password"
                          type={showPassword ? "text" : "password"}
                          placeholder="******************"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="md:ml-2">
                        <label
                          className="block mb-2 text-sm font-bold text-gray-700"
                          for="c_password"
                        >
                          Confirm Password
                        </label>
                        <input
                          className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                          id="c_password"
                          type={showPassword ? "text" : "password"}
                          placeholder="******************"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="">
                      <button
                        onClick={handleTogglePassword}
                        className="bg-blue-500 rounded text-gray-100 px-2 py-1"
                      >
                        {showPassword ? "Hide Password" : "Show Password"}
                      </button>
                    </div>
                  </div>
                  <div className="pb-2">
                    {errors &&
                      errors.map((error) => (
                        <>
                          <p className="text-red-600 text-sm">
                            {error.message}
                          </p>
                        </>
                      ))}
                  </div>
                  <div className="mb-6 text-center">
                    <button
                      className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                    onClick={handleSubmit}
                    >
                      Register Account
                    </button>
                  </div>
                  <hr className="mb-6 border-t" />
                </form>
              </div>
            </div>
          </div>
        </div>
        {loading && <Loader />}
      </div>
    </div>
  );
};

export default EditUser;
