import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Dreadcrumb from "../dreadcrumb";
const EditAcademicInfoForm = ({ closeEdit, userData }) => {
  const [seeSchoolName, setSeeSchoolName] = useState(
    userData.academicInfo.seeSchoolName
  );
  const [seeFaculty, setSeeFaculty] = useState(
    userData.academicInfo.seeFaculty
  );
  const [seeGpa, setSeeGpa] = useState(userData.academicInfo.seeGpa);
  const [seePercentage, setSeePercentage] = useState(
    userData.academicInfo.seePercentage
  );
  const [seeMarksheetImage, setSeeMarksheetImage] = useState(null);
  const [seeCharacterCertificateImage, setSeeCharacterCertificateImage] =
    useState(null);
  const [plus2CollegeName, setPlus2CollegeName] = useState(
    userData.academicInfo.plus2CollegeName
  );
  const [plus2Faculty, setPlus2Faculty] = useState(
    userData.academicInfo.plus2Faculty
  );
  const [plus2Gpa, setPlus2Gpa] = useState(userData.academicInfo.plus2Gpa);
  const [plus2Percentage, setPlus2Percentage] = useState(
    userData.academicInfo.plus2Percentage
  );
  const [plus2TranscriptImage, setPlus2TranscriptImage] = useState(null);
  const [plus2MigrationImage, setPlus2MigrationImage] = useState(null);
  const [documentOneImage, setDocumentOneImage] = useState(null);
  const [anotherDocument, setAnotherDocument] = useState(null);
  const [plus2CharacterCertificateImage, setPlus2CharacterCertificateImage] =
    useState(null);
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      // Handle file input
      const file = files[0];
      if (name === "seeMarksheetImage") {
        setSeeMarksheetImage(file);
      } else if (name === "seeCharacterCertificateImage") {
        setSeeCharacterCertificateImage(file);
      } else if (name === "plus2TranscriptImage") {
        setPlus2TranscriptImage(file);
      } else if (name === "plus2MigrationImage") {
        setPlus2MigrationImage(file);
      } else if (name === "plus2CharacterCertificateImage") {
        setPlus2CharacterCertificateImage(file);
      } else if (name === "documentOneImage") {
        setDocumentOneImage(file);
      } else if (name === "anotherDocument") {
        setAnotherDocument(file);
      }
    } else {
      // Handle text input
      if (name === "seeSchoolName") {
        setSeeSchoolName(value);
      } else if (name === "seeFaculty") {
        setSeeFaculty(value);
      } else if (name === "seeGpa") {
        setSeeGpa(value);
        setSeePercentage("");
      } else if (name === "seePercentage") {
        setSeePercentage(value);
        setSeeGpa("");
      } else if (name === "plus2CollegeName") {
        setPlus2CollegeName(value);
      } else if (name === "plus2Faculty") {
        setPlus2Faculty(value);
      } else if (name === "plus2Gpa") {
        setPlus2Gpa(value);
        setPlus2Percentage("");
      } else if (name === "plus2Percentage") {
        setPlus2Percentage(value);
        setPlus2Gpa("");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "user-token": Cookies.get("user-token"),
        },
      };
      const formDataToSend = new FormData();
      formDataToSend.append("seeSchoolName", seeSchoolName);
      formDataToSend.append("seeFaculty", seeFaculty);
      formDataToSend.append("seeGpa", seeGpa);
      formDataToSend.append("seePercentage", seePercentage);
      formDataToSend.append("seeMarksheetImage", seeMarksheetImage);
      formDataToSend.append(
        "seeCharacterCertificateImage",
        seeCharacterCertificateImage
      );
      formDataToSend.append("userId", userData.academicInfo.user);
      formDataToSend.append("plus2CollegeName", plus2CollegeName);
      formDataToSend.append("plus2Faculty", plus2Faculty);
      formDataToSend.append("plus2Gpa", plus2Gpa);
      formDataToSend.append("plus2Percentage", plus2Percentage);
      formDataToSend.append("plus2TranscriptImage", plus2TranscriptImage);
      formDataToSend.append("plus2MigrationImage", plus2MigrationImage);
      formDataToSend.append("documentOneImage", documentOneImage);
      formDataToSend.append("anotherDocument", anotherDocument);
      formDataToSend.append(
        "plus2CharacterCertificateImage",
        plus2CharacterCertificateImage
      );
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/form/academic_info/update`,
        formDataToSend,
        config
      );
      if (response.data.message) {
        Swal.fire("Success", response.data.message, "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full mx-auto fixed top-0 left-0 z-[999] overflow-y-auto h-[100vh] bg-white">
      <Dreadcrumb page="Edit Academic Information" />
      <div className="flex justify-end p-2">
        <button
          onClick={closeEdit}
          className="bg-red-600 text-white rounded px-2 py-1"
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
      <form
        onSubmit={handleSubmit}
        className=" shadow-md rounded lg:px-8 px-2 pt-6 pb-8 mb-4  gap-4"
      >
        <hr className="mt-2 mb-2" />
        <div className="grid lg:grid-cols-10 grid-cols-1 gap-2">
          <div className="mb-4 col-span-3">
            <label
              htmlFor="seeSchoolName"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              SEE/SLC School Name*
            </label>
            <input
              type="text"
              name="seeSchoolName"
              id="seeSchoolName"
              placeholder="Enter your school name"
              value={seeSchoolName}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4 lg:col-auto col-span-3">
            <label
              htmlFor="seeFaculty"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              SEE/SLC Faculty*
            </label>
            <input
              type="text"
              name="seeFaculty"
              id="seeFaculty"
              placeholder="Faculty"
              value={seeFaculty}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="mb-4 lg:col-auto col-span-3">
            <label
              htmlFor="seeGpa"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              SEE/SLC GPA*
            </label>
            <input
              type="text"
              name="seeGpa"
              id="seeGpa"
              value={seeGpa}
              placeholder="0.00"
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 lg:col-auto col-span-3">
            <label
              htmlFor="seePercentage"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              SEE/SLC Percentage*
            </label>
            <input
              type="text"
              name="seePercentage"
              id="seePercentage"
              placeholder="00.00"
              value={seePercentage}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 col-span-2">
            <label
              htmlFor="seeMarksheetImage"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              SEE/SLC Marksheet Image*
            </label>
            <input
              type="file"
              name="seeMarksheetImage"
              id="seeMarksheetImage"
              accept="image/*"
              onChange={handleInputChange}
            />
            <img
              src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.seeMarksheetImage}`}
              className="w-[15rem] cursor-pointer"
              title="Click to View Larger"
            />
            {seeMarksheetImage && (
              <img
                src={URL.createObjectURL(seeMarksheetImage)}
                alt="SEEMarksheet Image Preview"
                className="mt-2 max-w-xs"
              />
            )}
          </div>
          <div className="mb-4 col-span-2">
            <label
              htmlFor="seeCharacterCertificateImage"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              SEE/SLC Character Certificate Image*
            </label>
            <input
              type="file"
              name="seeCharacterCertificateImage"
              id="seeCharacterCertificateImage"
              accept="image/*"
              onChange={handleInputChange}
            />
            <img
              src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.seeCharacterCertificateImage}`}
              className="w-[15rem] cursor-pointer"
            />

            {seeCharacterCertificateImage && (
              <img
                src={URL.createObjectURL(seeCharacterCertificateImage)}
                alt="SEE/SLC Character Certificate Image Preview"
                className="mt-2 max-w-xs"
              />
            )}
          </div>
        </div>
        <hr className="mt-2 mb-2" />
        <div className="grid lg:grid-cols-10 grid-cols-1 gap-2">
          <div className="mb-4 col-span-3">
            <label
              htmlFor="plus2CollegeName"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              +2 College Name*
            </label>
            <input
              type="text"
              name="plus2CollegeName"
              id="plus2CollegeName"
              placeholder="Enter your College name"
              value={plus2CollegeName}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          {/* Example for text input with label */}
          <div className="mb-4 lg:col-auto col-span-3">
            <label
              htmlFor="plus2Faculty"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              +2 Faculty*
            </label>
            <input
              type="text"
              name="plus2Faculty"
              id="plus2Faculty"
              placeholder="Faculty"
              value={plus2Faculty}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          {/* Example for text input with label */}
          <div className="mb-4 lg:col-auto col-span-3">
            <label
              htmlFor="plus2Gpa"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              +2 GPA*
            </label>
            <input
              type="text"
              name="plus2Gpa"
              id="plus2Gpa"
              value={plus2Gpa}
              placeholder="0.00"
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {/* Example for text input with label */}
          <div className="mb-4 lg:col-auto col-span-3">
            <label
              htmlFor="plus2Percentage"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              +2 Percentage*
            </label>
            <input
              type="text"
              name="plus2Percentage"
              id="plus2Percentage"
              placeholder="00.00"
              value={plus2Percentage}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 col-span-2">
            <label
              htmlFor="plus2TranscriptImage"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              +2 Transcript Image*
            </label>
            <input
              type="file"
              name="plus2TranscriptImage"
              id="plus2TranscriptImage"
              accept="image/*"
              onChange={handleInputChange}
            />
            <img
              src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.plus2TranscriptImage}`}
              className="w-[15rem] cursor-pointer"
            />
            {plus2TranscriptImage && (
              <img
                src={URL.createObjectURL(plus2TranscriptImage)}
                alt="+2 Transcript Image Preview"
                className="mt-2 max-w-xs"
              />
            )}
          </div>

          {/* Example for file input with image preview */}
          <div className="mb-4 col-span-2">
            <label
              htmlFor="plus2MigrationImage"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              +2 Migration Image*
            </label>
            <input
              type="file"
              name="plus2MigrationImage"
              id="plus2MigrationImage"
              accept="image/*"
              onChange={handleInputChange}
            />
            <img
              src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.plus2MigrationImage}`}
              className="w-[15rem] cursor-pointer"
            />
            {plus2MigrationImage && (
              <img
                src={URL.createObjectURL(plus2MigrationImage)}
                alt="+2 Migration Image Preview"
                className="mt-2 max-w-xs"
              />
            )}
          </div>

          {/* Example for file input with image preview */}
          <div className="mb-4 col-span-2">
            <label
              htmlFor="plus2CharacterCertificateImage"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              +2 Character Certificate Image*
            </label>
            <input
              type="file"
              name="plus2CharacterCertificateImage"
              id="plus2CharacterCertificateImage"
              accept="image/*"
              onChange={handleInputChange}
            />
            <img
              src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.plus2CharacterCertificateImage}`}
              className="w-[15rem] cursor-pointer"
            />
            {plus2CharacterCertificateImage && (
              <img
                src={URL.createObjectURL(plus2CharacterCertificateImage)}
                alt="+2 Character Certificate Image Preview"
                className="mt-2 max-w-xs"
              />
            )}
          </div>
          <div className="mb-4 col-span-2">
            <label
              htmlFor="documentone"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              Document one {"(if you have any.)"}
            </label>
            <input
              type="file"
              name="documentOneImage"
              id="documentOneImage"
              accept="image/*"
              onChange={handleInputChange}
            />
            {documentOneImage && (
              <img
                src={URL.createObjectURL(documentOneImage)}
                alt="+2 Document One Image Preview"
                className="mt-2 max-w-xs"
              />
            )}
          </div>

          <div className="mb-4 col-span-2">
            <label
              htmlFor="anotherDocument"
              className="block text-gray-700 text-xs font-bold mb-2"
            >
              Another Document {"(if you have any.)"}
            </label>
            <input
              type="file"
              name="anotherDocument"
              id="anotherDocument"
              accept="image/*"
              onChange={handleInputChange}
            />
            {anotherDocument && (
              <img
                src={URL.createObjectURL(anotherDocument)}
                alt="Another Document Image Preview"
                className="mt-2 max-w-xs"
              />
            )}
          </div>
        </div>

        <div className="mb-4">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditAcademicInfoForm;
