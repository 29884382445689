import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
const PaymentStatus = ({ userId,refreshPayment}) => {
  const [paymentStatus, setPaymentStatus] = useState(false);
  const fetchPaymentStatus = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/api/form/payment/admin/check/${userId}`
    );
    if (response.data) {
      setPaymentStatus(response.data.payment);
    }
  };

  const makePaymentComplete = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/form/set-payment/${userId}`
    );
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: "success",
      title: "payment has been updated successfully",
    });
    refreshPayment()
    fetchPaymentStatus()
  };

  useEffect(() => {
    fetchPaymentStatus();
  }, [userId]);
  return (
    <div>
      {paymentStatus ? (
        <p className="text-green-600">Completed</p>
      ) : (
        <button onClick={() => makePaymentComplete()} className="text-gray-50 bg-gray-600 px-2 py-1 rounded">Make Payment Complete</button>
      )}
    </div>
  );
};

export default PaymentStatus;
